// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;
			margin-top: 1rem;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			margin-top: 1.5rem;
			font-size: 1rem;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: 1.5rem;
			font-weight: 700;

			.amount {
				font-size: 1.5rem;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1.5rem;

			.amount {
				font-size: 1.5rem;
			}
		}

	}
}

// parks-collection-section
// =========================================================================
.parks-collection-section {
	position: relative;
	background: $secondary;

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 50vw;
		height: 100%;
		max-width: 548px;
		max-height: 673px;
		background: url('/images/swirl-left.svg') no-repeat left center;
		opacity: 0.4;
	}

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 50vw;
		height: 100%;
		max-width: 548px;
		max-height: 673px;
		background: url('/images/swirl-right.svg') no-repeat right center;
		opacity: 0.4;
	}

	// collection
	.collection {
		z-index: 30;
		margin-top: -100px;

		&.grid {
			.grid-items {
				.item {
					&:first-child {
						@include media-breakpoint-up(xl) {
							transform: translateY(-100px);
						}
					}

					&:nth-child(3n) {
						@include media-breakpoint-up(xl) {
							transform: translateY(-60px);
						}
					}

					&:nth-child(4n) {
						@include media-breakpoint-up(xl) {
							transform: translateY(-80px);
						}
					}
				}
			}
		}

		.card {
			@include media-breakpoint-up(xl) {
				height: auto !important;
				border: none;
			}

			box-shadow: $shadow;

			.card-logo {
				display: block;
				margin: 10px 15px;
				text-align: center;

				img {
					max-height: 100px;
				}
			}

			.card-body {
				@include media-breakpoint-up(xl) {
					padding: 20px;
				}

				.card-caption {
					.card-title-link {
						color: $primary;

						.card-title {
							@include font-size($h2-font-size);
						}

						&:hover {
							.card-title {
								color: $secondary;
							}
						}
					}
				}
			}
		}
	}
}

// intro-collection-section
// =========================================================================
.intro-collection-section {
	position: relative;
	background: $tertiary;

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 50vw;
		height: 100%;
		max-width: 548px;
		max-height: 673px;
		background: url('/images/swirl-left.svg') no-repeat left center;
		opacity: 0.4;
	}

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 50vw;
		height: 100%;
		max-width: 548px;
		max-height: 673px;
		background: url('/images/swirl-right.svg') no-repeat right center;
		opacity: 0.4;
	}

	// intro
	.intro-container {
		position: relative;
		z-index: 30;

		.container-holder {
			.column {
				&.one {
					@include media-breakpoint-up(md) {
						@include make-col(8);
					}
				}

				&.two {
					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
				}
			}
		}

		// collection
		.collection {
			&.grid {
				.grid-items {
					.item {
						@include media-breakpoint-up(lg) {
							@include make-col(6);
						}

						&:first-child {
							.card {
								@include media-breakpoint-up(lg) {
									margin-top: -80px;
								}
							}
						}
					}
				}
			}

			.card {
				border: none;
				box-shadow: $shadow;

				.card-img-overlay {
					top: auto;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-end;
					background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);

					@include media-breakpoint-up(xl) {
						padding: 20px;
					}

					.card-caption {
						margin-top: auto;
						text-shadow: none;

						.card-title {
							margin-bottom: 0;
							@include font-size($h2-font-size);
						}

						.card-subtitle {
							margin: 0.375rem 0 0 0;
						}
					}
				}
			}
		}
	}

	// banner
	.banner-container {
		position: relative;
		z-index: 1;
		max-width: 1320px;
		margin-top: 6vh;

		.container-holder {
			@extend .no-gutters;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;
			background: $primary;
			border-radius: 6px;
			box-shadow: $shadow;
			overflow: hidden;

			.column {
				margin-bottom: 0;

				&.one {
					padding: 50px !important;
					color: $white;

					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					h2,
					h3 {
						margin-bottom: 30px;
						color: $white;
					}

					p {
						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}

				&.two {
					@include media-breakpoint-up(lg) {
						@include make-col(8);
					}

					padding-left: 0 !important;
					padding-right: 0 !important;

					img {
						width: 100%;

						@include media-breakpoint-only(md) {
							width: auto;
							min-width: 100%;
							max-width: unset;
						}
					}
				}
			}
		}
	}

}

// outro-section
// =========================================================================
.outro-section {
	position: relative;
	@extend .bg-light;
	padding-bottom: calc(6vh + 127px) !important;
	text-align: center;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 127px;
		background: url('/images/wave-green-down.svg') no-repeat center top $white;
		background-size: 100% auto;
	}

	// wysiwyg
	.wysiwyg {
		max-width: 720px;
		margin: 0 auto;
		text-align: center;
	}

}

&.w3-channel-poelman {
	.outro-section {
		&::after {
			background-color: $blue;
		}
	}
}

// map-section
// =========================================================================
.map-section {
	position: relative;
	@extend .bg-light;
	padding-bottom: calc(6vh + 127px) !important;
	background-color: $blue !important;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 127px;
		background: url('/images/wave-blue-down.svg') no-repeat center top $white;
		background-size: 100% auto;
	}

	.container-three-columns {
		.container-holder {
			align-items: flex-start;

			.column {
				max-width: unset;
				flex: 1;

				&.one {}

				&.two {
					position: relative;
					min-width: 330px;
					max-width: 330px;
					margin: 0 auto 15px auto;

					a.link {
						position: absolute;
						padding: 2px 6px 1px 6px;
						background-color: $green;
						border-radius: 3px;
						color: $white;
						font-size: 15px;
						font-weight: 700;
						text-transform: uppercase;
						text-decoration: none;
						box-shadow: 0 3px 6px rgba($black, 0.15);

						&::before {
							content: '';
							position: absolute;
							height: 3px;
							background-color: $green;
							transform-origin: left;
							transition: 0.5s;
						}

						&::after {
							content: '';
							position: absolute;
							width: 16px;
							height: 16px;
							background-color: $green;
							border: 2px solid $white;
							box-shadow: 0 0 0 1px $blue;
							border-radius: 100%;
							transition: 0.5s;
						}

						&:hover {
							background-color: $green-dark;

							&::before {
								background-color: $green-dark;
							}

							&::after {
								z-index: 1;
								background-color: $green-dark;
							}
						}

						&.location-bergsehaak {
							top: 22%;
							left: 43%;

							&::before {
								bottom: 0;
								left: 71%;
								width: 66%;
								transform: rotate(52deg);
							}

							&::after {
								top: 340%;
								left: 106%;
							}
						}

						&.location-remboevillage {
							top: 33%;
							left: 14%;

							&::before {
								bottom: 0;
								left: 90%;
								width: 37%;
								transform: rotate(25deg);
							}

							&::after {
								top: 145%;
								left: 115%;
							}
						}

						&.location-hertenhorst {
							top: 50%;
							left: 15%;

							&::before {
								top: 50%;
								left: 100%;
								width: 22%;
								transform: rotate(-2deg);
							}

							&::after {
								top: 10%;
								left: 120%;
							}
						}

						&.location-bronckhorst {
							top: 61%;
							left: 20%;

							&::before {
								top: 0;
								left: 85%;
								width: 49%;
								transform: rotate(-17deg);
							}

							&::after {
								top: -125%;
								left: 126%;
							}
						}

						&.location-detienhoeve {
							top: 71%;
							left: 32%;

							&::before {
								top: 0;
								left: 74%;
								width: 47%;
								transform: rotate(-58deg);
							}

							&::after {
								top: -285%;
								left: 97%;
							}
						}
					}
				}
			}
		}
	}
}

// facilities-collection-section
// =========================================================================
.facilities-collection-section {
	position: relative;
	padding: 6vh 0;
	background: $tertiary;

	&::before {
		content: "";
		display: none; // hide
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 50vw;
		height: 100%;
		max-width: 548px;
		max-height: 673px;
		background: url('/images/swirl-left.svg') no-repeat left center;
		opacity: 0.4;
	}

	&::after {
		content: "";
		display: none; // hide
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 50vw;
		height: 100%;
		max-width: 548px;
		max-height: 673px;
		background: url('/images/swirl-right.svg') no-repeat right center;
		opacity: 0.4;
	}

	.container-two-columns {
		.column {
			&.one {
				@include media-breakpoint-up(lg) {
					@include make-col(4);
				}
			}

			&.two {
				@include media-breakpoint-up(lg) {
					@include make-col(8);
				}
			}
		}
	}

	// collection
	.collection {
		z-index: 1; // above bg effect

		&.masonry {
			.card-columns {
				.item {
					&:first-child {
						.card {
							@include media-breakpoint-up(lg) {
								margin-top: 30px;
							}

							background: transparent;
							border: none;
							word-wrap: unset;

							.card-image {
								display: none;
							}

							.card-img-overlay {
								position: static;
								background: transparent;
								padding: 0;

								.card-caption {
									display: flex;
									flex-wrap: wrap;
									color: inherit;
									text-shadow: none;

									.card-title {
										@include font-size($h1-font-size);
									}

									.card-description {
										margin-bottom: 1rem;
									}

									.card-subtitle {
										order: 3;
										margin: 0;
										@extend .btn;
										@extend .btn-primary;
										font-family: $font-family-primary;
									}
								}
							}
						}
					}

					&:not(:first-child) {
						.card {
							border: none;

							.card-img-overlay {
								top: auto;
								display: flex;
								flex-wrap: wrap;
								justify-content: flex-end;
								background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);

								@include media-breakpoint-up(xl) {
									padding: 20px;
								}

								.card-caption {
									margin-top: auto;
									text-shadow: none;

									.card-title {
										margin-bottom: 0;
										font-size: $h4-font-size;
										font-weight: 400;
									}

									.card-subtitle,
									.card-description {
										display: none;
									}
								}
							}
						}
					}

					&:nth-child(5n) {
						.card {
							@include media-breakpoint-up(lg) {
								margin-top: 30px;
							}
						}
					}
				}
			}
		}
	}
}

// environment-collection-section
// =========================================================================
.environment-collection-section {
	@extend .bg-light;
	position: relative;
	padding-top: calc(6vh + 127px) !important;
	padding-bottom: calc(6vh + 127px) !important;
	background: url('/images/swirl.svg') center center no-repeat $secondary;
	background-size: contain;

	@include media-breakpoint-up(xl) {
		min-height: 914px;
		background-size: 1642px 659px;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 127px;
		margin-bottom: -1px; // pixel fix
		background: url('/images/wave-green-up.svg') no-repeat center bottom $tertiary;
		background-size: cover;

		@include media-breakpoint-down(lg) {
			background-size: 100% auto;
		}
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 127px;
		margin-top: -1px; // pixel fix
		background: url('/images/wave-green-down.svg') no-repeat center top $white;
		background-size: 100% auto;
	}

	// wysiwyg
	.wysiwyg {
		max-width: 720px;
		margin: 0 auto;
		text-align: center;
	}

	// collection
	.collection {
		margin-top: 30px;

		.grid-items {
			align-items: flex-start;
		}

		.card {
			border: none;

			.card-img-overlay {
				top: auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);

				@include media-breakpoint-up(xl) {
					padding: 20px;
				}

				.card-caption {
					margin-top: auto;
					text-shadow: none;

					.card-title {
						margin-bottom: 0;
						font-size: $h4-font-size;
						font-weight: 400;
					}

					.card-subtitle,
					.card-description {
						display: none;
					}
				}
			}
		}

		.item {
			&:first-child {
				.card {
					@include media-breakpoint-up(lg) {
						margin-top: 80px;
					}
				}
			}

			&:last-child {
				.card {
					@include media-breakpoint-up(lg) {
						margin-top: 40px;
					}
				}
			}
		}
	}
}

// detienhoeve
&.detienhoeve {
	.environment-collection-section {
		&::before {
			background-image: url('/images/wave-yellow-up.svg');
		}

		&::after {
			background-image: url('/images/wave-yellow-down.svg');
		}
	}
}

// news-section
// =========================================================================
.news-section {
	padding: 6vh 0;
	background: $white;

	h3 {
		@include font-size($h4-font-size);
		color: $blue;
		font-weight: 400;
	}

	.column {

		&.two,
		&.three {
			font-size: 16px;
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;
			}
		}

		.card {
			@extend .default-card;
		}
	}

	// mini-filter
	.mini-filter {
		margin-bottom: 30px;
		padding: 15px 15px 0 15px;
		background-color: $white;
		border-radius: 6px;
		box-shadow: $shadow;

		@include media-breakpoint-up(xl) {
			padding: 30px 30px 15px 30px;
		}

		form {
			label {
				font-size: 18px;
			}

			.form-control {
				font-size: 18px;
			}
		}
	}

	// results
	.results {
		margin-bottom: 30px;
	}

}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	position: relative;
	margin: 6vh 0;

	// image-collection
	.image-collection {
		z-index: 1;
		position: relative;
	}

	// gallery-label
	.gallery-label {
		top: 15px;
		padding: 5px 15px;
		border-radius: 0 6px 6px 0;
		font-weight: 700;
	}

}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
