// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #91b508;
$green-light: #e7eed0;
$green-dark: #2a3c24;

$yellow: #c5c724;
$yellow-light: #e2e391;
$yellow-dark: #434413;

$blue: #6dc7ea;

$white: #fff;
$black: #000;

// css3 root vars setup
:root {
	@include defineColorHSLA(--w3-primary-color, $green-dark);
	@include defineColorHSLA(--w3-secondary-color, $green);
	@include defineColorHSLA(--w3-tertiary-color, $green-light);
	@include defineColorHSLA(--w3-quaternary-color, $blue);
}

// theme-colors
$primary: var(--w3-primary-color);
$secondary: var(--w3-secondary-color);
$tertiary: var(--w3-tertiary-color);
$quaternary: var(--w3-quaternary-color);

// body
$body-bg: $tertiary;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;

// typography
@import url('https://fonts.googleapis.com/css2?family=Sansita:wght@400;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
$font-family-primary: 'Outfit', sans-serif;
$font-family-secondary: 'Sansita', sans-serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
$font-weight-base: 300;
$line-height-base: 1.25;

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 1.875; // 30px
$h3-font-size: $font-size-base * 1.375; // 22px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-line-height: 1;
$headings-color: $primary;

// contextual
$light: $secondary;
$dark: $primary;
$text-light: $white;
$text-dark: $white;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;
$matrix-color-tertiary: $tertiary;

// shadow
$shadow: 0 0 20px rgba($black, 0.25);

// border
$border-radius: 6px;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
*/
