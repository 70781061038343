// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: $primary;
	transition: 0.5s;

	&::after {
		content: "";
		z-index: -1;
		position: absolute;
		top: calc(100% - 1px);
		left: 0;
		right: 0;
		height: 113px;
		background: url('/images/header-bg.svg') no-repeat center top;
		background-size: 100% auto;
	}

	.container-fluid {
		max-width: 2560px;

		.container-holder {
			>.column {
				padding: 15px;
				justify-content: flex-end;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@include media-breakpoint-up(xl) {
					padding: 20px 45px 15px 45px;
					align-items: center;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {

		.navbar-toggler {
			width: 30px;
			padding: 0;
			border: none;
			color: $white;
			font-size: 20px;
			text-align: left;
			transition: color 0.5s;

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				color: $secondary;
			}
		}
	}

	// logo
	.logo {
		max-width: 175px;
		margin-right: auto;

		@include media-breakpoint-down(xl) {
			margin-bottom: -15px;
		}

		@include media-breakpoint-up(xl) {
			position: absolute;
			top: 30px;
			left: 45px;
			max-width: 300px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
				max-height: 100px;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 4;

			.navbar-nav {
				margin-top: 30px;

				>.nav-item {
					font-size: 17px;
					font-weight: 500;

					>.nav-link {
						display: block;
						color: $white;

						&:hover {
							color: $secondary;
						}
					}

					&.active {
						>.nav-link {
							color: $secondary;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 30px;
						padding: 0;
						border: none;
						background-color: transparent;

						.nav-item {
							font-size: 17px;
							font-weight: 500;
							line-height: normal;

							.nav-link {
								color: $white;

								&:hover {
									color: $secondary;
								}
							}

							&.active {
								.nav-link {
									color: $secondary;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;

				>.nav-item {
					margin-right: 5px;

					>.nav-link {
						display: block;
						padding: 0 0.5rem;
						color: $white;
						font-size: 16px;
						font-weight: 500;
						line-height: 36px;

						&:hover {
							color: $secondary;
						}

						&.highlight {
							display: block;
							padding: 0 15px;
							border-radius: 4px;
							background: $secondary;
							color: $white;
							font-size: 16px;
							font-weight: 500;
							line-height: 36px;
							text-decoration: none;

							&:hover {
								background: darken($secondary, 15%);
							}
						}
					}

					&.active {
						>.nav-link {
							color: $secondary;

							&.highlight {
								background: darken($secondary, 15%);
								color: $white;
							}
						}
					}
				}

				.dropdown-menu {
					.nav-item {
						.nav-link {
							font-weight: 500;

							&:hover {
								color: $secondary;
							}
						}

						&.active {
							.nav-link {
								color: $secondary;
							}
						}
					}
				}
			}
		}
	}

	// quick-icons
	.quick-icons {
		ul {
			margin-left: 15px;

			li {
				a {
					display: block;
					color: $white;
					font-size: 18px;

					span {
						display: none;
					}

					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 20px;
	}
}

&.default {
	.header {
		.logo {
			max-width: 150px;

			@include media-breakpoint-up(xl) {
				top: 20px;
				max-width: 300px;
			}
		}
	}
}

&.main {
	.header {
		.quick-icons {
			display: none;
		}
	}
}

&.detienhoeve {
	.header {
		background: $white;

		&::after {
			background-image: url('/images/header-bg-white.svg');
		}

		// menu-toggle
		.menu-toggle {
			.navbar-toggler {
				color: $primary;

				&:hover {
					color: $secondary;
				}
			}
		}

		// menu
		.menu {
			@include media-breakpoint-down(lg) {
				.navbar-nav {
					>.nav-item {
						>.nav-link {
							color: $primary;

							&:hover {
								color: $secondary;
							}
						}

						&.active {
							>.nav-link {
								color: $secondary;
							}
						}
					}

					.nav-item {
						.dropdown-menu {
							.nav-item {
								.nav-link {
									color: $white;

									&:hover {
										color: $secondary;
									}
								}

								&.active {
									.nav-link {
										color: $secondary;
									}
								}
							}
						}
					}
				}
			}

			@include media-breakpoint-up(xl) {
				.navbar-nav {
					>.nav-item {
						>.nav-link {
							color: $primary;

							&:hover {
								color: $secondary;
							}

							&.highlight {
								color: $primary;
							}

							&.active {
								>.nav-link {
									color: $secondary;
								}
							}
						}

						.dropdown-menu {
							.nav-item {
								.nav-link {
									&:hover {
										color: $secondary;
									}
								}

								&.active {
									.nav-link {
										color: $secondary;
									}
								}
							}
						}
					}
				}
			}
		}

		// quick-icons
		.quick-icons {
			display: none; // hide

			ul {
				li {
					a {
						color: $primary;

						&:hover {
							color: $secondary;
						}
					}
				}
			}
		}

		// site-switcher
		.site-switcher {
			display: none; // hide
		}
	}
}
