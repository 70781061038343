// mini-sab

&.main {

	.mini-sab {
		background: $tertiary;
		.container {
			position: relative;
			@extend .d-flex;
			@extend .justify-content-center;

			.container-holder {
				z-index: 100;
				position: relative;
				min-height: 100px;
				margin: 0;
				padding: 15px 0;
				background: $quaternary;

				@include media-breakpoint-down(lg) {
					margin: -100px 0 6vh 0;
					border-radius: 30px;
					bottom: 175px;
				}

				// above eyecatcher
				@include media-breakpoint-up(lg) {
					position: absolute;
					left: auto;
					right: auto;
					bottom: 150px;
					border-radius: 50px;
					padding: 10px 30px 15px 30px;
				}
				@include media-breakpoint-up(xl) {
					bottom: 225px;
				}
			}
		}
	}
}

.mini-sab {
	background: $tertiary;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			min-height: 100px;
			margin: 0;
			padding: 15px 0;
			background: $quaternary;

			@include media-breakpoint-down(lg) {
				margin: -100px 0 6vh 0;
				border-radius: 30px;
			}

			// above eyecatcher
			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 50px;
				border-radius: 50px;
				padding: 10px 30px 15px 30px;
			}

			@include media-breakpoint-up(xl) {
				bottom: 120px;
			}
		}
	}
}
