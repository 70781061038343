// footer
.footer {
	@extend .bg-dark;
	position: relative;
	margin-top: calc(6vh + 125px) !important;
	padding-top: 6vh !important;

	&::before {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 0;
		right: 0;
		height: 127px;
		margin-bottom: -1px; // pixel fix
		background: url('/images/wave-green-dark-up.svg') no-repeat center bottom;
		background-size: 100% auto;
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 0;
		right: 0;
		width: 115px;
		height: 90px;
		margin: 0 auto;
		background: url('/images/logo-visual.svg');
	}

	// footer-outro
	.footer-outro {

		h1,
		.h1,
		h2,
		.h2,
		h3,
		.h3,
		h4,
		.h4,
		h5,
		.h5,
		h6,
		.h6 {
			color: $white;
		}

		.container-two-columns {
			.column {
				&.one {
					@include media-breakpoint-up(lg) {
						@include make-col(8);
					}
				}

				&.two {
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}
				}
			}
		}

		// footer-text
		.footer-text {

			ul {
				display: flex;
				flex-wrap: wrap;
				margin: 25px 0;
				padding: 0;
				list-style: none;

				li {
					margin: 5px 10px 5px 0;

					// tel/mailto
					a {
						display: inline-flex;
						align-items: center;
						padding: 8px 10px;
						background: $white;
						border-radius: 6px;
						color: $primary !important;
						font-size: 15px;
						font-weight: 700;
						text-decoration: none !important;

						&::before {
							font-family: $font-awesome;
							display: inline-block;
							margin-right: 10px;
							font-size: 18px;
							font-weight: 900;
							line-height: 1;
						}

						&[href^="tel:"] {
							&::before {
								content: "\f095";
							}
						}

						&[href^="mailto:"] {
							&::before {
								content: "\f0e0";
							}
						}

						&:hover {
							background: $secondary;
							color: $white;
						}
					}
				}
			}
		}

		// faq-category
		.faq-category {
			.faq-items {
				margin: 10px 0 0 0;

				.faq-item {
					margin-bottom: 0;
					border-bottom-color: $white;

					&:last-of-type {
						border-bottom: none;
					}

					.faq-question {
						h3 {
							font-size: 18px;
						}
					}

					.faq-answer {
						margin-top: 5px;
						font-size: 18px;
					}
				}
			}
		}
	}

	// footer-parks
	.footer-parks {
		margin: 6vh 15px 0 15px;
		color: $black;
		text-align: center;

		h1,
		.h1,
		h2,
		.h2,
		h3,
		.h3,
		h4,
		.h4,
		h5,
		.h5,
		h6,
		.h6 {
			color: $black;
		}

		a {
			color: $black !important;
		}

		.container {
			max-width: 1290px;

			.container-holder {
				background: $white;
			}

			&:first-of-type {
				.container-holder {
					border-radius: 6px 6px 0 0;
				}
			}

			&:last-of-type {
				.container-holder {
					border-radius: 0 0 6px 6px;
				}
			}
		}

		.footer-parks-intro {
			.footer-text {
				max-width: 540px;
				margin: 40px auto;
			}
		}

		.footer-parks-list {
			font-size: 15px;

			.container-holder {
				margin-bottom: 0;

				.column {
					flex-flow: row wrap;
					justify-content: center;
				}
			}

			.footer-text {
				max-width: 220px;
				margin: 15px;

				a {
					color: $primary !important;
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 15px 0 35px 0;
		color: $white;
		font-size: 14px;

		.footer-link {
			.list {
				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				justify-content: center;

				.list-item {
					margin: 5px 30px 5px 0;

					.link {
						color: $white;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

	}
}

// home
&.home {
	.footer {
		margin-top: 125px !important;

		&::before {
			background-color: $white;
		}
	}
}

// detienhoeve
&.detienhoeve {
	.footer {
		&::before {
			background-image: url('/images/wave-yellow-dark-up.svg');
		}
	}
}
