// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 80vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;

	.owl-carousel {
		.owl-container {
			max-width: 720px;
		}

		.item {
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(
					180deg,
					rgba($black, 0) 0%,
					rgba($black, 0.5) 100%
				);
			}

			.owl-caption {
				position: relative;
				text-shadow: 6px 6px 25px rgba($black, 0.15);

				.owl-title {
					// @include font-size($h1-font-size);

					// @include media-breakpoint-up(xl) {
					// 	font-size: 56px;
					// }
					// title is overlapping the mini-sab on windows because of default zoomlevel 125%
					font-size: 5vh;

					font-weight: 400;
					line-height: 1.2;
				}
			}
		}

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}

	&::after {
		content: "";
		z-index: 20;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 127px;
		margin-bottom: -1px; // pixel fix
		background: url("/images/wave-green-light-up.svg") no-repeat center
			bottom;
		background-size: 100% auto;
	}
}

// home
&.home {
	&.main {
		.eyecatcher {
			&::after {
				background-image: url("/images/wave-green-up.svg");
			}
		}
	}

	&.default {
		.eyecatcher {
			&::after {
				background-image: url("/images/wave-green-light-up.svg");
			}
		}
	}
}

// detienhoeve
&.detienhoeve {
	.eyecatcher {
		&::after {
			background-image: url("/images/wave-yellow-light-up.svg");
		}
	}
}
