// body
& {
	font-size: 19px;

	@include media-breakpoint-up(xl) {
		font-size: 21px;
	}
}

// main
&:not(.home) {
	.main {
		position: relative;

		&::before {
			content: "";
			z-index: -1;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 50vw;
			height: 100%;
			max-width: 548px;
			max-height: 673px;
			background: url('/images/swirl-left.svg') no-repeat left center;
			opacity: 0.4;
		}

		&::after {
			content: "";
			z-index: -1;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			width: 50vw;
			height: 100%;
			max-width: 548px;
			max-height: 673px;
			background: url('/images/swirl-right.svg') no-repeat right center;
			opacity: 0.4;
		}
	}
}

// headings
&.home {
	h2 {
		@include font-size($h1-font-size);
	}

	.footer {
		h2 {
			@include font-size($h2-font-size);
		}
	}
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

strong {
	font-weight: 700;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	box-shadow: $shadow;

	.card-image {
		.card-image-link {
			.card-image-caption {
				top: 15px;
				right: auto;
				bottom: auto;
				border-radius: 0 6px 6px 0;
				background-color: $secondary;
				font-weight: 700;
			}
		}
	}

	.card-body {
		@include media-breakpoint-up(xl) {
			padding: 20px;
		}

		.card-caption {
			display: flex;
			flex-direction: column;

			.card-title-link {
				color: $primary;
			}

			.card-title {
				@include font-size($h2-font-size);
				color: $primary;
			}

			.card-description {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex-grow: 1;

				.list {
					li {
						padding: 0 0 0 36px;

						img {
							width: 26px;
						}
					}
				}
			}
		}
	}
}

// specifications
.specifications {
	margin-top: 30px;

	table {
		margin-top: 30px;

		td {
			border-top: none;
		}
	}
}
